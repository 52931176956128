<template>
  <div class="page--mask-station login-success">
    <div v-if="$SO.size(userProfile)" class="container-mask-station">
      <img src="/static/img/mask-station/logo.png" class="logo" />

      <div class="user-info">
        <h2 class="user-info__greeting">
          Hello,<br />
          <span class="user-info__fullname"
            >{{ userProfile.first_name | ucwords }} {{ userProfile.last_name | ucwords }}!</span
          >
        </h2>

        <GImageUser
          class="user-info__ava-bg"
          :username="userProfile.user_name"
          :src="userProfile.image"
          class-name="user-info__ava"
        />

        <p class="user-info__copywriting">
          Di sini, kamu bisa melihat rekomendasi masker yang<br />sudah kami sesuaikan dengan Beauty Profile kamu.<br />
          Yuk, lihat sekarang!
        </p>
      </div>
    </div>

    <img src="/static/img/mask-station/masker-with-hand-bottom.png" class="main-banner" />
  </div>
</template>

<script>
export default {
  name: 'LoginSuccess',
  computed: {
    userProfile() {
      return this.$store.state.auth.userProfile;
    },
  },
  created() {
    setTimeout(() => {
      this.$router.push('/mask-station/beauty-profile');
    }, 3200);
  },
};
</script>
